import React from 'react';
import { createRoot } from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';

import App from './components/App';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: '2a0d148c-af6f-41c2-809c-f46d5c525428',
    clientToken: 'pub6914242216f26afee064bf923f414906',
    site: 'datadoghq.eu',
    service: 'return-portal',
    env: process.env.DATADOG_ENV,
    sessionSampleRate: 5,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
