import React, { Component } from 'react';

import ErrorPage from './ErrorPage';
import { ReturnPortalContext } from '../../contexts/ReturnPortalContext';

type Props = {
  children: React.ReactNode;
};

class ErrorBoundary extends Component<Props> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ReturnPortalContext.Consumer>
          {({ settings }) => <ErrorPage font={settings?.font} color={settings?.accentColor} />}
        </ReturnPortalContext.Consumer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
