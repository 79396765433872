import { useParams } from 'react-router-dom';

export default function useWithBaseUrl(path: string) {
  const { hash, route } = useParams<{ hash?: string; route?: string }>();

  if (hash) {
    return `/${hash}${path}`;
  }

  if (route) {
    return `/${route}/pages${path}`;
  }

  return `/pages${path}`;
}
