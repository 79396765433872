import { AxiosError } from 'axios';

export function isApiError(e?: unknown | null): e is AxiosError {
  if (!e) {
    return false;
  }

  return (e as AxiosError).response !== undefined;
}

export function isConflictError(e: unknown | null): e is AxiosError {
  if (!e) {
    return false;
  }

  return isApiError(e) && e.response?.status === 409;
}

export function isUnauthorizedError(e?: unknown | null): boolean {
  if (!e) {
    return false;
  }

  return isApiError(e) && e.response?.status === 401;
}

export function getApiErrorCode(e?: unknown | null): number | undefined {
  if (isApiError(e)) {
    return e.response?.status;
  }

  return undefined;
}
