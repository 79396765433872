import { useState, useCallback } from 'react';

import { Auth } from '../types';
import { login } from '../api';

type LoginCredentials = { orderId: string; email: string; shopId?: number };

type Result = {
  isLoading: boolean;
  error: unknown | null;
  login: (credentials: LoginCredentials) => Promise<boolean>;
};

export function getAuth(): Auth {
  try {
    const authJSON = sessionStorage.getItem('auth');

    if (authJSON) {
      return JSON.parse(authJSON);
    }
  } catch (e) {
    console.error('Failed to parse auth from session storage', e);
  }

  return { token: '', exp: 0 };
}

function setAuth(auth: Auth): void {
  sessionStorage.setItem('auth', JSON.stringify(auth));
}

function useAuth(): Result {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const handleLogin = useCallback(async (credentials: LoginCredentials): Promise<boolean> => {
    const { orderId, email, shopId } = credentials;
    setError(null);
    setIsLoading(true);

    try {
      const auth = await login(orderId, email, shopId);

      setIsLoading(false);
      setAuth(auth);
    } catch (error) {
      setError(error);
      setIsLoading(false);

      return false;
    }

    return true;
  }, []);

  return { isLoading, error, login: handleLogin };
}

export default useAuth;
