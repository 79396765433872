import React, { useState, FormEvent } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import ErrorIcon from '../../icons/error.svg';

import Input from '../common/Input';
import Button from '../common/Button';
import { TextTrans } from '../common/Text';
import Link from '../common/Link';
import Heading1 from '../common/Heading1';
import colors from '../common/colors';
import Column from '../common/layout/Column';
import { useSettings } from '../../hooks/useSettings';

interface FormProps {
  hasError: boolean;
}

const FormContainer = styled('form')<FormProps>`
  margin-top: ${(props: FormProps) => (props.hasError ? '20px' : '32px')};
`;

const ContentContainer = styled('div')`
  width: 100%;
`;

const ButtonContainer = styled('div')`
  margin-top: 32px;
`;

const StyledErrorIcon = styled(ErrorIcon)`
  flex-shrink: 0;
  fill: ${colors.red};
  margin-right: 12px;
`;

const ErrorContainer = styled('div')`
  display: flex;
  margin-top: 20px;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${colors.lightRed};
  flex-shrink: 2;
`;

const PolicyLinkContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 24px 0 20px 0;
  font-size: 12px;
  flex-shrink: 2;
`;

type Props = {
  hasError?: boolean;
  isLoading?: boolean;
  onSubmit?: (orderId: string, email: string) => void;
};

function LoginForm(props: Props) {
  const [orderId, setOrderId] = useState('');
  const [email, setEmail] = useState('');
  const { hasError = false, isLoading, onSubmit = () => {} } = props;
  const { accentColor, policyLink } = useSettings();
  const { t } = useTranslation();

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(orderId, email);
  };

  return (
    <ContentContainer>
      <Column spacing={4}>
        <Heading1>{t('login_heading')}</Heading1>
        <TextTrans secondary>login_description</TextTrans>
      </Column>
      {hasError && (
        <ErrorContainer>
          <StyledErrorIcon />
          <TextTrans>login_error</TextTrans>
        </ErrorContainer>
      )}
      <FormContainer hasError={hasError} onSubmit={onSubmitHandler}>
        <Column spacing={16}>
          <Input
            value={orderId}
            name="orderId"
            label="login_orderNumber"
            onChange={(value) => setOrderId(value)}
            placeholder="login_orderNumberPlaceholder"
            color={accentColor}
          />
          <Input
            value={email}
            name="email"
            type="email"
            label="login_email"
            onChange={(value) => setEmail(value)}
            placeholder="login_emailPlaceholder"
            color={accentColor}
          />
        </Column>
        <ButtonContainer>
          <Button
            type="submit"
            name="login"
            disabled={!orderId || !email}
            isLoading={isLoading}
            fullWidth
            color={accentColor}
          >
            {t('login_submitButton')}
          </Button>
        </ButtonContainer>
        {policyLink && (
          <PolicyLinkContainer>
            <Link color={accentColor} href={policyLink}>
              {t('login_returnPolicy')}
            </Link>
          </PolicyLinkContainer>
        )}
      </FormContainer>
    </ContentContainer>
  );
}

export default React.memo(LoginForm);
