import React from 'react';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';

import Link from '../common/Link';
import { useSettings } from '../../hooks/useSettings';

import FooterLogo from '../../images/footer-logo.svg';

const Container = styled('div')`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  font-size: 12px;
  line-height: 20px;
`;

const Logo = styled(FooterLogo)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

function Footer() {
  const { accentColor } = useSettings();
  useTranslation();

  return (
    <Container>
      <Logo />
      <span>
        <Trans i18nKey="promoFooter">
          <Link color={accentColor} fontWeight="500" href="https://sevensenders.com/" />
        </Trans>
      </span>
    </Container>
  );
}

export default React.memo(Footer);
