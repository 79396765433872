declare global {
  interface Window {
    heap: {
      addEventProperties(properties: Record<string, string | number | undefined>): void;
    };
  }
}

export function heapAddEventProperties(properties: Record<string, string | number | undefined>) {
  window.heap?.addEventProperties(properties);
}
