type ParseDomainResult = {
  subdomains: string[];
  domain: string;
  topLevelDomain: string;
};

function parseDomain(hostname: string): ParseDomainResult {
  const domains = hostname.split('.');

  return {
    topLevelDomain: domains[domains.length - 1],
    domain: domains[domains.length - 2],
    subdomains: domains.slice(0, domains.length - 2),
  };
}

/**
 * Example: shop.foo.bar.returns.com
 * shop - 3st level subdomain (branded url subdomain)
 * foo - 2st level subdomain
 * bar - 1nd level subdomain
 * returns - domain
 * com - top level domain
 */
export function getReturnPortalSubdomain(hostname: string): string | undefined {
  const subdomainLevel = parseInt(process.env.REACT_APP_BRANDED_URL_SUBDOMAIN_LEVEL || '');
  const { subdomains } = parseDomain(hostname);

  if (isNaN(subdomainLevel)) {
    return;
  }

  return subdomains[subdomains.length - subdomainLevel];
}

function getReturnPortalRoute(pathname: string): string {
  const uri = decodeURIComponent(pathname);
  const found = uri.match(/^\/([^/]+)(\/|$)/);

  if (found && found[1] !== 'pages') {
    return found[1];
  }

  return '';
}

type GeneralUrlResult = {
  hash: string;
};

type BrandedUrlResult = {
  subdomain: string;
  route: string;
};

type WrongUrlResult = Record<string, never>;

export type UrlParseResult = GeneralUrlResult | BrandedUrlResult | WrongUrlResult;

export function parseReturnPortalUrl(hostname: string, pathname: string): UrlParseResult {
  const subdomain = getReturnPortalSubdomain(hostname);
  const route = getReturnPortalRoute(pathname);

  if (subdomain) {
    return { subdomain, route };
  } else if (route) {
    return { hash: route };
  }

  return {};
}

export function isBrandedUrlResult(result: UrlParseResult): result is BrandedUrlResult {
  return !!(result as BrandedUrlResult).subdomain;
}

export function isGeneralUrlResult(result: UrlParseResult): result is GeneralUrlResult {
  return !!(result as GeneralUrlResult).hash;
}
